import React, { useContext } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Theme,
  themeContext
} from '../ThemeContextProvider/ThemeContextProvider'
import { BasicProps, MergeReactElementProps } from '../Types'

const useStyles = (theme: Theme) =>
  makeStyles(() =>
    createStyles({
      button: {
        backgroundColor: theme.secondaryColor,
        padding: '10px 30px',
        clipPath:
          'polygon(7% 0, 100% 0, 100% 20%, 100% 80%, 93% 100%, 0 100%, 0 80%, 0 20%)',
        WebkitClipPath:
          'polygon(7% 0, 100% 0, 100% 20%, 100% 80%, 93% 100%, 0 100%, 0 80%, 0 20%)',
        border: 'none',
        cursor: 'pointer',
        transition: '0.2s',
        borderRadius: '1px',
        fontSize: '1rem',
        '&:focus': {
          outline: 'none'
        }
      },
      hover: {
        '&:hover': {
          clipPath:
            'polygon(0 0, 93% 0, 100% 20%, 100% 80%, 100% 100%, 7% 100%, 0 80%, 0 20%)',
          WebkitClipPath:
            'polygon(0 0, 93% 0, 100% 20%, 100% 80%, 100% 100%, 7% 100%, 0 80%, 0 20%)'
        }
      },
      disabled: {
        opacity: '0.8',
        cursor: 'default'
      }
    })
  )

export interface SBButtonProps extends BasicProps {
  children?: React.ReactNode
  onClick?: (event: React.ChangeEvent<{}>) => void
  disabled?: boolean
  hoverEffect?: boolean
}

type Props = MergeReactElementProps<'button', SBButtonProps>

export const SBButton = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLButtonElement>) => {
    const {
      children,
      onClick,
      disabled = false,
      className,
      style,
      hoverEffect = true,
      id,
      ...other
    } = props
    const theme = useContext(themeContext)
    const classes = useStyles(theme)()
    return (
      <button
        ref={ref}
        disabled={disabled}
        style={style}
        className={`${classes.button} ${!!className && className} ${
          hoverEffect && !disabled && classes.hover
        } ${disabled && classes.disabled}`}
        onClick={!disabled ? onClick : () => {}}
        id={id}
        {...other}
      >
        {children}
      </button>
    )
  }
)
